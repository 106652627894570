<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#005f32"
        spinner="bar-fade-scale"
      />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #ffffff">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #ffffff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-start>
        <v-flex xs12 sm12 >
          <v-layout wrap>
            <v-flex xs12 md12 lg12>
              <v-layout wrap>
                <v-flex xs12>
                  <DonationNewDesign />
                </v-flex>
              
  
      <!-- Donation List in v-layout and v-flex -->
      <v-layout row wrap justify-space-between py-2>
        <v-flex xs12>
          <span style=" color: #005f32;font-family: montserratbold;font-size: 20px; ">
            Recent Donations
           </span>
        </v-flex>
      
        <v-flex v-for="(donation, index) in donationDetails.slice(0, 5)" :key="index" xs12 sm4 md2 lg2 style=" color: black;font-family: montserratbold;">
          <div class="donation-card pa-3">
            <div class="donor-name font-weight-bold">{{ donation.firstname }} {{ donation.lastname }}</div>
            <div class="donation-amount green--text font-weight-bold">₹ {{donation.amount}}</div>
          </div>
        </v-flex>
      </v-layout>

     
              </v-layout>
            </v-flex>
         
          </v-layout>
        </v-flex>

        <v-layout wrap justify-center>
          <v-flex xs12>
            <CBRCStory />
          </v-flex>
        </v-layout>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  import DonationNewDesign from "./donationNewDesign.vue";
  import CBRCStory from "./cbrcStory.vue";
  export default {
    data() {
      return {
        donation: 5000,
        firstCard: true,
        secondCard: false,
        radio: null,
        images: [],
        donationData: [],
        donationDetails:[],
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        msg: null,
        timeout: 3000,
      };
    },
    components: {
      DonationNewDesign,
      CBRCStory
    },
    mounted() {
      this.getList();
      this.getData();
    },
  
    methods: {
    
      toTextField() {
        this.donation = null;
        document.getElementById("my_button").onclick = function () {
          document.getElementById("my_textbox").focus();
        };
      },
  
      getList() {
        this.appLoading = true;
        axios({
          method: "get",
          url: "/donationtype/getbyname",
          params: {
            name: this.$route.query.DonationName,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.donationData = response.data.data;
              this.images = response.data.data.images;
            } else {
              this.showSnackBar = true;
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err());
            this.ServerError = true;
          });
      },
  
      getData() {
        this.appLoading = true;
       
        axios({
          method: "post",
          url: "/Donation/all",
          headers: {
              "x-auth-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoid3RpYWRtaW4iLCJ1c2VybmFtZSI6Ind0aWFkbWluIiwiZW1haWwiOiJ3dGlhZG1pbkB3dGkuY29tIiwicGhvbmUiOiI5NTY4NDEyMzc1IiwiaWQiOiI1ZTE0MGNjMGIyZmQ3NTAwMTFhZDA1ZTYiLCJ1c2VydHlwZSI6IkFkbWluIiwiaWF0IjoxNjY5MTg3NDMxfQ.XBvv9ywKVI_ZrlxA6bzNNEhZGa3NQ2ewGrTyh3fFIPw",
            },
            data: {
              status: "success"
          }
         
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.donationDetails = response.data.data;
              this.images = response.data.data.images;
            } else {
              this.showSnackBar = true;
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err());
            this.ServerError = true;
          });
      },
    },
  };
  </script>
  <style  scoped>
  .v-text-field--outlined >>> fieldset {
    border-color: grey;
    border-radius: 5px;
  }
  .v-radio >>> label {
    font-size: 20px;
    font-family: poppinsregular;
    color: black;
  }
  .center >>> input {
    text-align: center;
    font-family: poppinsregular;
    font-size: 32px;
  }
  .center2 >>> label {
    text-align: center;
    font-family: poppinsregular;
    font-size: 20px;
  }
  .boldR >>> label {
    font-family: poppinsbold;
  }
  .animate {
    position: relative;
    animation: mymove 0.25s;
  }
  @keyframes mymove {
    from {
      left: -500px;
    }
    to {
      left: 0px;
    }
  }
  #div1 {
    animation-timing-function: linear;
  }
  .v-divider {
    display: none !important;
  }
  
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear; /* Safari */
    animation: spin 2s linear;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>